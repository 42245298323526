import revive_payload_client_4sVQNw7RlN from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_RMzWu406ID from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import unhead_KgADcZ0jPj from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_2LpVRIemQY from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_t2GMTTFnMT from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import vuetify_no_client_hints_wMKVLl9fyU from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import plugin_AUP22rrBAc from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _01_auth_5uMEwSfOVJ from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/plugins/01.auth.ts";
import countryFlags_5ztp7HpzAa from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/plugins/countryFlags.ts";
import datadog_8fWxeViGWQ from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/plugins/datadog.ts";
import i18n_KYRD8MIJaU from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/plugins/i18n.ts";
import vuetify_dialog_rZniY7Po79 from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/plugins/vuetify-dialog.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  vuetify_icons_RMzWu406ID,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  view_transitions_client_2LpVRIemQY,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  plugin_t2GMTTFnMT,
  vuetify_no_client_hints_wMKVLl9fyU,
  plugin_AUP22rrBAc,
  _01_auth_5uMEwSfOVJ,
  countryFlags_5ztp7HpzAa,
  datadog_8fWxeViGWQ,
  i18n_KYRD8MIJaU,
  vuetify_dialog_rZniY7Po79,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]