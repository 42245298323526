import { default as indexgdzRAxulhPMeta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/action-plan/index.vue?macro=true";
import { default as indexbkpvJSvZfkMeta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/admin/[section]/index.vue?macro=true";
import { default as indexwckF9RGOuzMeta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/admin/index.vue?macro=true";
import { default as index3pUpWIRCMyMeta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/country/[type]/[summaryBy]/index.vue?macro=true";
import { default as _91type_93vDrgJjfVP3Meta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/country/[type].vue?macro=true";
import { default as countryLtlv2RdCMpMeta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/country.vue?macro=true";
import { default as indexKEBaXggobOMeta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/eval/[diagType]/create/index.vue?macro=true";
import { default as indexZbRlCDoIWrMeta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/eval/[diagType]/themes/[themeId]/index.vue?macro=true";
import { default as indexO9mWlgOCnJMeta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/eval/[diagType]/themes/[themeId]/subThemes/[subThemeId]/index.vue?macro=true";
import { default as indexPudUBxGTToMeta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/eval/[diagType]/themes/[themeId]/subThemes/[subThemeId]/questions/index.vue?macro=true";
import { default as _91subThemeId_93AqaB9MvKa1Meta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/eval/[diagType]/themes/[themeId]/subThemes/[subThemeId].vue?macro=true";
import { default as _91themeId_93r1XharMAlUMeta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/eval/[diagType]/themes/[themeId].vue?macro=true";
import { default as indexq1rqtDJrSKMeta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/eval/[diagType]/themes/index.vue?macro=true";
import { default as themesrtAJmjjwXxMeta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/eval/[diagType]/themes.vue?macro=true";
import { default as indexG4V03NAgYMMeta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/index.vue?macro=true";
import { default as indexR3uVteW9LeMeta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/region/[type]/[summaryBy]/index.vue?macro=true";
import { default as _91type_93imHWTokFjyMeta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/region/[type].vue?macro=true";
import { default as regionLNS2ZNtvdJMeta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/region.vue?macro=true";
import { default as indexqLsQWlAERWMeta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/store/index.vue?macro=true";
import { default as indextuEeO6oEx0Meta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/choose-store/index.vue?macro=true";
import { default as index8RR7IufXA6Meta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/error/index.vue?macro=true";
import { default as tokenBodBSqv0iYMeta } from "/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/token.vue?macro=true";
export default [
  {
    name: "origin-action-plan",
    path: "/:origin()/action-plan",
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/action-plan/index.vue").then(m => m.default || m)
  },
  {
    name: "origin-admin-section",
    path: "/:origin()/admin/:section()",
    meta: indexbkpvJSvZfkMeta || {},
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/admin/[section]/index.vue").then(m => m.default || m)
  },
  {
    name: "origin-admin",
    path: "/:origin()/admin",
    meta: indexwckF9RGOuzMeta || {},
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "origin-country",
    path: "/:origin()/country",
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/country.vue").then(m => m.default || m),
    children: [
  {
    name: "origin-country-type",
    path: ":type()",
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/country/[type].vue").then(m => m.default || m),
    children: [
  {
    name: "origin-country-type-summaryBy",
    path: ":summaryBy()",
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/country/[type]/[summaryBy]/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "origin-eval-diagType-create",
    path: "/:origin()/eval/:diagType()/create",
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/eval/[diagType]/create/index.vue").then(m => m.default || m)
  },
  {
    name: themesrtAJmjjwXxMeta?.name,
    path: "/:origin()/eval/:diagType()/themes",
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/eval/[diagType]/themes.vue").then(m => m.default || m),
    children: [
  {
    name: _91themeId_93r1XharMAlUMeta?.name,
    path: ":themeId()",
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/eval/[diagType]/themes/[themeId].vue").then(m => m.default || m),
    children: [
  {
    name: "origin-eval-diagType-themes-themeId",
    path: "",
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/eval/[diagType]/themes/[themeId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91subThemeId_93AqaB9MvKa1Meta?.name,
    path: "subThemes/:subThemeId()",
    meta: _91subThemeId_93AqaB9MvKa1Meta || {},
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/eval/[diagType]/themes/[themeId]/subThemes/[subThemeId].vue").then(m => m.default || m),
    children: [
  {
    name: "origin-eval-diagType-themes-themeId-subThemes-subThemeId",
    path: "",
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/eval/[diagType]/themes/[themeId]/subThemes/[subThemeId]/index.vue").then(m => m.default || m)
  },
  {
    name: "origin-eval-diagType-themes-themeId-subThemes-subThemeId-questions",
    path: "questions",
    meta: indexPudUBxGTToMeta || {},
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/eval/[diagType]/themes/[themeId]/subThemes/[subThemeId]/questions/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "origin-eval-diagType-themes",
    path: "",
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/eval/[diagType]/themes/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "origin",
    path: "/:origin()",
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/index.vue").then(m => m.default || m)
  },
  {
    name: "origin-region",
    path: "/:origin()/region",
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/region.vue").then(m => m.default || m),
    children: [
  {
    name: "origin-region-type",
    path: ":type()",
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/region/[type].vue").then(m => m.default || m),
    children: [
  {
    name: "origin-region-type-summaryBy",
    path: ":summaryBy()",
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/region/[type]/[summaryBy]/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "origin-store",
    path: "/:origin()/store",
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/[origin]/store/index.vue").then(m => m.default || m)
  },
  {
    name: "choose-store",
    path: "/choose-store",
    meta: indextuEeO6oEx0Meta || {},
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/choose-store/index.vue").then(m => m.default || m)
  },
  {
    name: "error",
    path: "/error",
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/error/index.vue").then(m => m.default || m)
  },
  {
    name: "token",
    path: "/token",
    component: () => import("/home/runner/actions-runner/_work/myefficiency/myefficiency/frontend/app/pages/token.vue").then(m => m.default || m)
  }
]