

export const isDev = false
export function vuetifyConfiguration() {
  const options = {"theme":{"themes":{"light":{"dark":false,"colors":{"primary":"#3643BA","dark-primary":"#2E399E","gray":"#a0a0a0","dark-gray":"#4E5D6BCC","light-gray":"#F5F5F5","primary-light":"#D9ECF5","light-blue":"#9ADDFF","completed":"#149B65","in_progress":"#0113B6FF","to_be_processed":"#EC6607","yes":"#149B65","no":"#ff0000","not-concerned":"#3643BA"}}}}}
  
  
  
  
  return options
}

